import { createContext, useContext, useEffect, useState } from "react";
import { useGetAuthorByCode } from "./useGetAuthorByCode";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

function useAuthorLogin() {
  const [refetch, { loading, error, data, done, undone }] = useGetAuthorByCode()

  const [author, setAuthor] = useState(null)
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (!loading && !error) {
      console.log("data", data)
      setIsLogin(false)
      setAuthor(null)
      if (data) {
        if (data.author) {
          setAuthor(data.author)
          setIsLogin(true)
        }
      }
    }
  }, [data, loading, error])

  return [(code = "") => {
    console.log("SignIn...", { code })
    setIsLogin(false)
    setAuthor(null)
    refetch(code)
  }, () => {
    console.log("SignOut...")
    setIsLogin(false)
    setAuthor(null)
  }, {
    done,
    undone,
    loading,
    error,
    author,
    isLogin
  }]
}

const AuthorLoginContext = createContext({
  loading: false,
  error: null,
  author: null,
  isLogin: false,
  isFetched: false,
  done: false,
  undone() { console.warn("AuthorLoginContext.undone unimplemented") },
  signIn(code = "") { console.warn("AuthorLoginContext.signIn unimplemented", { code }) },
  signOut() { console.warn("AuthorLoginContext.signOut unimplemented") },
})

export function useAuthorLoginContext() {
  return useContext(AuthorLoginContext)
}


export function AuthorLoginProvider({ children }) {
  const [signIn, signOut, { done, undone, loading, error, author, isLogin }] = useAuthorLogin()

  return (
    <AuthorLoginContext.Provider value={{
      done,
      undone,
      loading,
      error,
      author,
      isLogin,
      signIn,
      signOut
    }}>
      {children}
    </AuthorLoginContext.Provider>
  )
}

export function AuthorLoginDialog({ open, onClose = () => { } }) {
  const { signIn, done, undone, author, loading, error: externalError, isLogin } = useAuthorLoginContext()

  const [error, setError] = useState("")

  useEffect(() => {
    if (loading) {
      setError("")
    }
  }, [loading])

  useEffect(() => {
    if (done) {
      console.log("Petición lista")
      if (author) {
        console.log("Autor listo", author)
      } else {
        console.log("Author no existe")
        setError("El autor no existe")
      }
    }
  }, [done, author])

  return (
    <Dialog
      header={isLogin && author ? "Bienvenido" : "Tengo un código de Escritor"}
      visible={open}
      onHide={() => {
        undone()
        setError("")
        onClose(false)
      }}>
      <div className="w-full">
        {
          loading && (
            <span>Loading...</span>
          )
        }

        {
          externalError && (
            <span>Error: {JSON.stringify(externalError.message)}</span>
          )
        }

        {
          isLogin && author ? (
            <AuthorInfoLogin author={author} onComplete={() => {
              setError("")
            }} />
          ) : (
            <AuthorFormLogin error={error} onCode={code => {
              signIn(code)
            }} />
          )
        }

        {/* <pre><code>{JSON.stringify({ loading, error, author, isLogin, isFetched }, null, 2)}</code></pre> */}

      </div >
    </Dialog>
  )
}

function AuthorInfoLogin({ author, onComplete = () => { } }) {
  const { signOut } = useAuthorLoginContext()

  return (
    <div className="flex flex-column align-items-center">
      {/* <pre><code>{JSON.stringify({ author }, null, 2)}</code></pre> */}
      <div className="w-full flex flex-column justif-content-center align-items-center p-5">
        <div className="p-5">
          <img className="w-8rem h-8rem border-circle" src={author.picture} />
          <div className="flex flex-column align-items-center p-3">
            <span className="font-bold">{author.name}</span>
            <span className="text-xs">{author.role}</span>
          </div>
        </div>
      </div>
      <Button label="Cerrar sesión" icon="pi pi-unlock" onClick={async () => {
        console.log("signOut", { author })
        await signOut()
        onComplete()
      }} />
    </div>
  )
}

function AuthorFormLogin({ onCode = () => { }, error }) {
  const [code, setCode] = useState("")

  return (
    <div className="w-full">
      <div className="flex flex-column gap-2 py-2">
        <Password className={error ? "p-invalid w-full" : " w-full"} value={code} onChange={event => setCode(event.target.value)}
          aria-describedby="author-fail" toggleMask feedback={false} inputClassName="w-full" autoFocus={true} />
        <small id="author-fail" className={error ? "text-red-500" : ""}>
          {error}
        </small>
      </div>
      <Button className="w-full" label="Acceder" icon="pi pi-lock" onClick={async () => {
        console.log("onCode", { code })
        onCode(code)
      }} />
    </div>
  )
}