import { ArticleDate } from "./article-date"
import { OpenLinkDialog } from "./open-link-dialog"
import './article.css'

export function ArticlesBoard({ loading, error, data }) {
  if (loading) return <span>loading</span>
  if (error) return <span>Error: {JSON.stringify(error)}</span>


  const mapColumnClassName = columnIndex => ([
    "xs:col md:col-6 lg:col-4 xl:col-2 md:border-right-1 border-gray-300 px-4",
    "xs:col md:col-6 lg:col-8 xl:col-8 xl:border-right-1 border-gray-300 px-4",
    // "xs:col md:col-6 lg:col-8 xl:col-3 xl:border-right-1 border-gray-300 px-4",
    "xs:col md:col-6 lg:col-12 xl:col-2 px-4",
  ][columnIndex - 1])


  let stream = []

  const streamCols1 = (data.articles || []).slice(1).filter(({ column }) => column === 1)
  const streamCols2 = (data.articles || []).slice(1).filter(({ column }) => column === 2)
  const streamCols3 = (data.articles || []).slice(1).filter(({ column }) => column === 3)
  const streamCols4 = (data.articles || []).slice(1).filter(({ column }) => column === 4)

  let lastIndexCols1 = 0
  let lastIndexCols2 = 0
  let lastIndexCols3 = 0
  let lastIndexCols4 = 0

  while (lastIndexCols1 < streamCols1.length || lastIndexCols2 < streamCols2.length || lastIndexCols3 < streamCols3.length || lastIndexCols4 < streamCols4.length) {
    for (let i = 0; i < 2; i++) {
      if (lastIndexCols1 < streamCols1.length) {
        stream.push(streamCols1[lastIndexCols1++])
      }
    }

    if (lastIndexCols2 < streamCols2.length) {
      stream.push(streamCols2[lastIndexCols2++])
    }

    if (lastIndexCols3 < streamCols3.length) {
      stream.push(streamCols3[lastIndexCols3++])
    }

    for (let i = 0; i < 4; i++) {
      if (lastIndexCols4 < streamCols4.length) {
        stream.push(streamCols4[lastIndexCols4++])
      }
    }
  }


  const articleMapper = ({ head, columnIndex }) => article => {
    const { _id, author, createdAt, title, titleSize, image, paragraphs, column } = article

    if (head) {
      return (
        <div key={_id} className="pb-5 text-xl text-gray-800 font-italic">
          <OpenLinkDialog
            article={article}
            title={title}
            image={image}
            author={author}
            paragraphs={paragraphs}
          >
            <h1 className={`hover:underline cursor-pointer text-4xl`}>
              {title}
            </h1>
            {
              image && (
                <img style={{ objectFit: "cover", cursor: 'pointer' }} className="main-image w-full h-30rem" src={image} />
              )
            }
          </OpenLinkDialog>

          <ArticleDate date={createdAt} />

          <div className="py-4">
            {
              author && (
                <div className="flex align-items-center lg:justify-content-start justify-content-center">
                  <img className="border-circle w-3rem h-3rem" src={author.picture} />
                  <div>
                    <div className="px-3 flex flex-column align-items-baseline">
                      <span>{author.name}</span>
                      <span className="text-xs">{author.role}</span>
                    </div>
                  </div>
                </div>
              )
            }
          </div>

          <div className="py-5">
            <div className="w-full border-none border-bottom-1 border-solid border-200" />
          </div>
        </div >
      )
    }

    if (column === 1) {
      return (
        <div key={_id} className="col sm:col-12 md:col-12 lg:col-6 xl:col-6 pb-5 text-xl text-gray-800 font-italic">
          <div className="flex">
            <OpenLinkDialog
              article={article}
              title={title}
              image={image}
              author={author}
              paragraphs={paragraphs}
            >
              {
                image && (
                  // <img style={{ objectFit: "cover" }} className="w-12rem h-12rem" src={image} />
                  <img style={{ objectFit: "cover", cursor: 'pointer' }} className="main-image w-12rem h-12rem" src={image} />
                )
              }
            </OpenLinkDialog>

            <div className="w-6 flex flex-column px-4">
              <OpenLinkDialog
                article={article}
                title={title}
                image={image}
                author={author}
                paragraphs={paragraphs}
                textSize={head ? "text-4xl" : "text-xl"}
              />
              <ArticleDate date={createdAt} padding="pb-2" />

              <div className="py-2">
                {
                  author && (
                    <div className="flex align-items-center lg:justify-content-start justify-content-start">
                      <img className="border-circle w-3rem h-3rem" src={author.picture} />
                      <div>
                        <div className="px-3 flex flex-column align-items-baseline">
                          <span className="text-lg lg:text-lg">{author.name}</span>
                          <span className="text-xs">{author.role}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          {/* <div className="py-5">
                        <div className="w-full border-none border-bottom-1 border-solid border-200" />
                    </div> */}
        </div>
      )
    }

    if (column === 2) {
      return (
        <div key={_id} className="w-12 pb-5 text-xl text-gray-800 font-italic">
          <div className="flex">
            <OpenLinkDialog
              article={article}
              title={title}
              image={image}
              author={author}
              paragraphs={paragraphs}
            >
              {
                image && (
                  // <img style={{ objectFit: "cover" }} className="w-12rem h-12rem" src={image} />
                  <img style={{ objectFit: "cover", cursor: 'pointer' }} className="main-image w-12rem h-12rem" src={image} />
                )
              }
            </OpenLinkDialog>
            <div className="w-6 flex flex-column px-4">
              <OpenLinkDialog
                article={article}
                title={title}
                image={image}
                author={author}
                paragraphs={paragraphs}
                textSize={head ? "text-4xl" : "text-xl"}
              />
              <div className="py-2">
                <ArticleDate date={createdAt} padding="pb-3" />

                {
                  author && (
                    <div className="flex align-items-center lg:justify-content-start justify-content-center">
                      <img className="border-circle w-3rem h-3rem" src={author.picture} />
                      <div>
                        <div className="px-3 flex flex-column align-items-baseline">
                          <span>{author.name}</span>
                          <span className="text-xs">{author.role}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="w-full border-none border-bottom-1 border-solid border-200" />
          </div>
        </div>
      )
    }

    if (column === 3) {
      return (
        <div key={_id} className="w-full pb-5 text-xl text-gray-800 font-italic">
          <div className="flex flex-column">

            <OpenLinkDialog
              article={article}
              title={title}
              image={image}
              author={author}
              paragraphs={paragraphs}
            >
              {
                image && (
                  // <img style={{ objectFit: "cover" }} className="w-12rem h-12rem" src={image} />
                  <img style={{ objectFit: "cover", cursor: 'pointer' }} className="main-image w-full h-30rem" src={image} />
                )
              }
            </OpenLinkDialog>

            <div className="flex flex-column pt-2">
              <OpenLinkDialog
                article={article}
                title={title}
                image={image}
                author={author}
                paragraphs={paragraphs}
                textSize={head ? "text-4xl" : "text-xl"}
              />
              <div className="py-2">
                <ArticleDate date={createdAt} padding="pb-4" />

                {
                  author && (
                    <div className="flex align-items-center lg:justify-content-start justify-content-center">
                      <img className="border-circle w-3rem h-3rem" src={author.picture} />
                      <div>
                        <div className="px-3 flex flex-column align-items-baseline">
                          <span>{author.name}</span>
                          <span className="text-xs">{author.role}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="w-full border-none border-bottom-1 border-solid border-200" />
          </div>
        </div>
      )
    }

    if (column === 4) {
      return (
        <div key={_id} className="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3 pb-5 text-xl text-gray-800 font-italic">
          <div className="flex flex-column px-2">

            <OpenLinkDialog
              article={article}
              title={title}
              image={image}
              author={author}
              paragraphs={paragraphs}
            >
              {
                image && (
                  // <img style={{ objectFit: "cover" }} className="w-12rem h-12rem" src={image} />
                  <img style={{ objectFit: "cover", cursor: 'pointer' }} className="main-image w-full h-12rem" src={image} />
                )
              }
            </OpenLinkDialog>

            <div className="w-full flex flex-column">
              <OpenLinkDialog
                article={article}
                title={title}
                image={image}
                author={author}
                paragraphs={paragraphs}
                textSize={"text-sm"}
              />
              <div className="py-2">
                <ArticleDate date={createdAt} />

                {
                  author && (
                    <div className="flex align-items-center lg:justify-content-start justify-content-start">
                      <div>
                        <div className="flex flex-column align-items-baseline">
                          <span className="text-sm">{author.name}</span>
                          <span className="text-xs">{author.role}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="w-full border-none border-bottom-1 border-solid border-200" />
          </div>
        </div>
      )
    }
  }

  return (
    <div className="w-full px-2 sm:px-4 md:px-6 lg:px-8 xl:px-8">
      {/* <h2>My first Apollo app 🚀</h2>
        <Button label="Actualizar" icon="pi pi-sync" onClick={() => refetch()} /> */}

      <div>
        {
          (data.articles || []).filter((_, index) => index === 0).map(articleMapper({ head: true, columnIndex: 0 }))
        }
      </div>

      <div className="grid">
        {
          stream.map(articleMapper({ head: false, columnIndex: 0 }))
        }
      </div>
      {/* <div className="flex flex-column">
                {
                    ([1, 2, 3]).map(columnIndex => (
                        <div key={columnIndex}>
                            {
                                (data.articles || [])
                                    .slice(1)
                                    .filter(({ column }) => column === columnIndex)
                                    .map(articleMapper({ head: false, columnIndex }))
                            }
                        </div>
                    ))
                }
            </div> */}
    </div >
  )
}