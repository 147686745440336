import { useAddArticleContext } from "../context/addArticleContext"
import { useAuthorLoginContext } from "../hooks/useAuthorLogin"

export function ReviewArticle({ head = false }) {

    const { author } = useAuthorLoginContext()

    const {
        picture,
        title,
        paragraphs
    } = useAddArticleContext()

    return (
        <div className="shadow-2 p-6">
            <h1 className="">{title || "SIN TÍTULO"}</h1>
            {
                picture && picture.url && (
                    <img style={{ objectFit: "cover" }} className="w-full h-28rem" src={picture.url} />
                )
            }
            <div className="py-4">
                {
                    author && (
                        <div className="flex align-items-center lg:justify-content-start justify-content-center">
                            <img className="border-circle w-3rem h-3rem" src={author.picture} />
                            <div>
                                <div className="px-3 flex flex-column align-items-baseline">
                                    <span>{author.name}</span>
                                    <span className="text-xs">{author.role}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                paragraphs && (
                    <div dangerouslySetInnerHTML={{
                        __html: paragraphs.map(line => line.replace(/\<img/g, `<img xstyle="height: 300px; object-fit: cover;" class="${head ? "w-full" : "w-full"}" `)).join("\n")
                    }} />
                )
            }
        </div>
    )
}