import { useEffect, useState } from "react";

const screenSizes = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}
// 576 - sm
// 768 - md
// 992 - lg
// 1200 - xl


export const useResize = () => {
    const [screenSize, setScreenSize] = useState(0)
    const [screenType, setScreenType] = useState("unknown")

    useEffect(() => {
        const updateSize = () => {
            const width = window.innerWidth
            setScreenSize(width)

            if (width < screenSizes.sm) {
                setScreenType("sm")
            } else if (width < screenSizes.md) {
                setScreenType("md")
            } else if (width < screenSizes.lg) {
                setScreenType("lg")
            } else {
                setScreenType("xl")
            }

        }

        updateSize()

        window.addEventListener("resize", updateSize)

        return () => {
            window.removeEventListener("resize", updateSize)
        }
    }, [])

    return {
        screenSize,
        screenType,
        isSmall: screenType === "sm",
        isMedium: screenType === "md",
        isLarge: screenType === "lg",
        isExtraLarge: screenType === "xl",
    }

};
