import { gql, useQuery } from "@apollo/client"

const GET_ARTICLES = gql`
  query GetArticles($primary: Boolean!) {
    articles(query: { primary: $primary }, sortBy: UPDATEAT_DESC) {
      _id
      author {
        name
        picture
        role
      }
      column
      createdAt
      credits
      image
      link
      paragraphs
      primary
      title
      titleSize
      type
      updateAt
    }
  }
`;

export function useGetArticles(primary = true) {
    const { refetch, ...info } = useQuery(GET_ARTICLES, {
      variables: {
        primary
      }
    })

    return [(primary = true) => refetch({
      primary
    }), info]
}