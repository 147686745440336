import { useState } from 'react';

import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-green/theme.css";

import { TestArticleQuery } from './TestArticleQuery';
import { AddArticle } from './components/add-article';
import { AuthorLoginDialog, AuthorLoginProvider } from './hooks/useAuthorLogin';
import { Navbar } from './components/nav-bar';
import { useGetArticles } from './hooks/useGetArticles';
import { ArticlesBoard } from './components/articles-board';
import { NavbarSimple } from './components/nav-bar-simple';
import "./components/navbar.css"

import useScrollPosition from "./hooks/useScroll";
import { AddArticleProvider } from './context/addArticleContext';


function Home() {
  const [refetch, { loading, error, data }] = useGetArticles(true)

  const [scrollRef, scrollPosition] = useScrollPosition();

  const [visible, setVisible] = useState(false);
  const [openLogin, setOpenLogin] = useState(false)

  return (
    <div ref={scrollRef} className='w-screen h-full overflow-auto' style={{ background: "#f8f3eb" }}>
      {/* <Navbar onWrite={() => setVisible(true)} onLogin={() => setOpenLogin(true)} onUserInfo={() => {
        setOpenLogin(true)}} /> 
        */}
      <NavbarSimple
        onWrite={() => setVisible(true)}
        onLogin={() => setOpenLogin(true)}
        onUserInfo={() => {
          setOpenLogin(true)
        }}
        scrollY={scrollPosition?.scrollY}
      />
      <div className='w-full flex justify-content-center' >
        {/* bold text */}
        {/* underline text */}
        <div className='page-title'>
          <h1 >
            Últimas noticias
          </h1>
        </div>
      </div>
      <div className='lg:px-0'>
        <AddArticle
          visible={visible}
          onComplete={() => {
            setVisible(false)
            refetch()
          }}
        />
        <AuthorLoginDialog
          open={openLogin}
          onClose={() => setOpenLogin(false)}
        // onLogin={() => onClose(false)}
        />
        {/* <TestArticleInsert /> */}
        {/* <TestAuthorQuery /> */}
        <div className='w-full lg:px-0 flex flex-column justify-content-center'>
          <ArticlesBoard loading={loading} error={error} data={data} />
        </div>
      </div>

    </div>
  )
}

function App() {
  return (
    <AuthorLoginProvider>
      <AddArticleProvider>
        <PrimeReactProvider>
          <Home />
        </PrimeReactProvider>
      </AddArticleProvider>
    </AuthorLoginProvider>
  );
}

export default App;
