import React, { useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import { WriteArticle } from './write-article';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useAuthorLoginContext } from '../hooks/useAuthorLogin';
import { Button } from 'primereact/button';
import { FrontPage } from './front-page';
import { ReviewArticle } from './review-article';
import { useAddArticle } from '../hooks/useAddArticle';
import { BlockUI } from 'primereact/blockui';
import { Tooltip } from 'primereact/tooltip';
import { useAddArticleContext } from '../context/addArticleContext';
import { useUpdateArticle } from '../hooks/useUpdateArticle';


export const AddArticle = ({ articleId, visible, onComplete = () => { }, createdAt, update = false }) => {
  const { author } = useAuthorLoginContext()
  const [addArticle, { loading, error, data }] = useAddArticle();
  const [updateArticle, updateArticleInfo] = useUpdateArticle();

  const { title, setTitle, picture, setPicture, paragraphs, setParagraphs } = useAddArticleContext()

  const [openDialog, setOpenDialog] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [columnSelected, setColumn] = useState(null);

  const cancelPopUp = useRef()
  const columNumber = [1, 2, 3, 4];

  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex === itemIndex;
    const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
    const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

    return (
      <div className='z-1 flex flex-column align-items-center'>
        <span
          className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer"
          style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '0px' }}
          onClick={() => setActiveIndex(itemIndex)}
        >
          <i className={`${item.icon} text-xl`} />
        </span>
        <span className='py-2'>
          {item.label}
        </span>
      </div>
    );
  };


  const items = [
    {
      icon: 'pi pi-file',
      label: 'Portada',
      template: (item) => itemRenderer(item, 0),
    },
    {
      icon: 'pi pi-file-edit',
      label: 'Escribir artículo',
      template: (item) => itemRenderer(item, 1)
    },
    {
      icon: 'pi pi-check',
      label: 'Revisar',
      template: (item) => itemRenderer(item, 2)
    }
  ];

  const handleMaximize = (event) => setFullScreen(event.maximized)

  const handlePrev = () => {
    if (activeIndex === 0) return
    setActiveIndex(prev => prev - 1)
  }

  const handleNext = () => {
    if (activeIndex > 2) return
    setActiveIndex(prev => prev + 1)
  }

  const chooseColumn = (event) => {
    confirmPopup({
      target: event.currentTarget,
    });
  }

  const handleAddArticle = async (event) => {
    event.preventDefault();

    const article = {
      type: "article-simple",
      title,
      titleSize: "normal",
      image: picture.url || null,
      paragraphs,
      primary: true,
      link: "https://ollama.com",
      column: columnSelected,
      credits: author.credits,
      author: {
        name: author.name,
        // code: author.code,
        role: author.role,
        picture: author.picture
      },
      createdAt: createdAt || new Date().toISOString(),
      updateAt: new Date().toISOString(),
    }

    console.log({ article });

    if (update) {
      await updateArticle({
        ...article,
        _id: articleId,

      })
    } else {
      await addArticle(article)
    }


    resetArticle()

    // if (paragraphs.length > 0) {
    //   addArticle(article)
    // }
  }

  const resetArticle = () => {
    setTitle("")
    setColumn(null)
    onComplete()
    setParagraphs([])
    setActiveIndex(0)

    setPicture({ url: null, file: null })

    console.log("CERRANDO ESCRIBIR ARTÍCULO")
  }

  const errorArticleTooltip = () => {
    if (title === "") return "Elija un título para el artículo."
    else if (!columnSelected) return "Elija la columna donde se publicará el artículo en el paso anterior."
    else if (paragraphs.length === 0) return "Escriba un artículo."
    else return ""
  }

  const actions = (
    <div className="flex lg:flex-row flex-column lg:justify-content-between gap-2 pt-2" >
      {
        author && (
          <div className="flex align-items-center lg:justify-content-start justify-content-center">
            <img className="border-circle w-3rem h-3rem" src={author.picture} />
            <div>
              <div className="px-3 flex flex-column align-items-baseline">
                <span>{author.name}</span>
                <span className="text-xs">{author.role}</span>
              </div>
            </div>
          </div>
        )
      }
      <div className="flex lg:flex-row flex-columnjustify-content-end gap-1">

        <Button ref={cancelPopUp} label="Cancelar" icon="pi pi-times" onClick={() => setOpenDialog(true)} className="p-button-text" />

        {
          activeIndex === 1 &&
          <Button
            label="Elegir columna"
            icon="pi pi-table"
            onClick={chooseColumn}
            tooltip="Elija la columna donde se publicará el artículo."
            tooltipOptions={{ position: 'top', hideDelay: 300 }}
          />
        }

        {
          activeIndex !== 0 && <Button label="Regresar" icon="pi pi-angle-left" onClick={handlePrev} />
        }

        {
          activeIndex === 2 ?

            <div>
              <Tooltip target=".disabled-button" />
              <Button
                label="Finalizar"
                onClick={handleAddArticle}
                disabled={!title || !columnSelected || paragraphs.length === 0}
                icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
                tooltip={errorArticleTooltip()}
                tooltipOptions={{
                  showOnDisabled: true,
                  position: 'top',
                  showDelay: 600,
                }}
              />
            </div>
            :
            <Button
              label="Siguiente"
              icon="pi pi-angle-right"
              iconPos="right"
              onClick={handleNext}
            />
        }
      </div>
    </div>
  );

  return (
    <Dialog
      header="Agregar Artículo"
      maximizable
      maximized={fullScreen}
      onMaximize={handleMaximize}
      visible={visible}
      style={{ width: '70vw' }}
      closeOnEscape={true}
      onHide={() => onComplete(false)}
      footer={actions}
    >

      <ConfirmPopup
        target={cancelPopUp.current}
        visible={openDialog}
        onHide={() => setOpenDialog(false)}
        message="¿Desea cancelar el artículo?"
        icon="pi pi-exclamation-triangle"
        accept={resetArticle}
        acceptLabel='Si'
        rejectLabel='No'
      />

      <ConfirmPopup content={
        <div className="column-article-container">
          {
            columNumber.map((column) => {
              return (
                <div
                  key={column}
                  className={(column === columnSelected) ? `column-article-selected` : `column-article`}
                  onClick={() => setColumn(column)}
                  style={{ width: column === 2 ? "60px" : "60px" }}
                >
                  {
                    column === 1 &&
                    <div className='column-inside-1'>
                      <i className='pi pi-image' ></i>
                      <i className='pi pi-image' ></i>
                    </div>
                  }
                  {
                    column === 2 &&
                    <div className='column-inside-2'>
                      <i className='pi pi-image' ></i>
                      <i className='pi pi-bars' ></i>
                    </div>
                  }
                  {
                    column === 3 &&
                    <div className='column-inside-3'>
                      <i className='pi pi-image' ></i>
                      <i className='pi pi-bars' ></i>
                    </div>
                  }
                  {
                    column === 4 &&
                    <div className='column-inside-4'>
                      <i className='pi pi-image' ></i>
                      <i className='pi pi-image' ></i>
                      <i className='pi pi-image' ></i>
                      <i className='pi pi-image' ></i>
                    </div>
                  }
                  <span style={{ position: "absolute", bottom: 20 }}>
                    {column}
                  </span>
                </div>
              );
            })
          }
        </div>
      } />

      <BlockUI blocked={loading} template={<i className="pi pi-lock" style={{ fontSize: '3rem' }}></i>}>
        <div className="card">
          <Steps model={items} activeIndex={activeIndex} readOnly={false} className="m-2 pt-4" />
          {activeIndex === 0 && <FrontPage />}
          {activeIndex === 1 && <WriteArticle />}
          {activeIndex === 2 && <ReviewArticle />}
        </div>
      </BlockUI>

    </Dialog >
  );
}

