
export const ArticleDate = ({ date = new Date(), padding = "" }) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const formatDate = new Date(date).toLocaleDateString('es-MX', options);

    return (
        <div className={`text-base ${padding}`}> {formatDate} </div>
    )
}
