import { useRef } from "react";

import { useAuthorLoginContext } from "../hooks/useAuthorLogin"

import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Menu } from "primereact/menu"
import { Badge } from "primereact/badge"



export const NavbarSimple = ({ onWrite = () => { }, onLogin = () => { }, onUserInfo = () => { }, scrollY }) => {
  const { isLogin, author, signOut } = useAuthorLoginContext();
  const menuLeft = useRef(null);
  const menuRight = useRef(null);


  const itemRenderer = (item) => (
    <div className='p-menuitem-content' onClick={item.command || (() => { })}>
      <a className="flex align-items-center p-menuitem-link">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
        {item.badge && <Badge className="ml-auto" value={item.badge} />}
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
    </div>
  );


  const itemsRight = [
    {
      command: () => {
        // toast.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
        console.log("INFO USUARIO...")
        onUserInfo()
      },
      template: (item, options) => {
        return (
          <button onClick={(e) => options.onClick(e)} className={'w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround'}>
            <img src={author?.picture} style={{ objectFit: " cover" }} width={"50px"} shape="circle" />
            <div className="flex flex-column align ml-3">
              <span className="font-bold">{author.name}</span>
              <span className="text-sm">{author.role}</span>
            </div>
          </button>
        );
      }
    },
    {
      separator: true
    },
    {
      label: 'Cerrar Sesión',
      icon: 'pi pi-sign-out',
      // shortcut: '⌘+Q',
      template: itemRenderer,
      command: () => {
        // toast.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
        signOut()
        console.log("CERRANDO SESIÓN...")
      },
    },

  ];

  const itemRendererLeft = (item) => (
    <div className='p-menuitem-content'>
      <a className="flex align-items-center p-menuitem-link" href={item.href} target="_blank">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
      </a>
    </div>
  );

  const itemsLeft = [
    {
      label: 'Whatsapp (771) 103 7615',
      icon: 'pi pi-whatsapp',
      template: itemRendererLeft,
      href: "https://wa.me/7711037615?text=Hola,%20quiero%20información%20de%20la%20publicidad%20desde%20$50%20pesos.%0D%0A%0D%0AEscribe%20tus%20datos%20de%20contacto%20aquí..."
    },
    {
      label: 'Correo contacto@osteoporosis.com',
      icon: 'pi pi-envelope',
      template: itemRendererLeft,
      href: "mailto:contacto@osteoporosis.com?subject=Información sobre el periódico&body=Hola, quiero información sobre el periódico.%0D%0A%0D%0AESCRIBE TUS DATOS DE CONTACTO AQUÍ..."
    },
    {
      separator: true
    },
    {
      label: 'Publicidad a partir de $50 MXN al mes',
      icon: 'pi pi-megaphone',
      template: itemRendererLeft,
      href: "mailto:contacto@osteoporosis.com?subject=Información sobre publicidad&body=Hola, quiero información de la publicidad desde $50 pesos.%0D%0A%0D%0AESCRIBE TUS DATOS DE CONTACTO AQUÍ..."
    },
  ];

  return <div className="navbar flex justify-content-between md:flex-row flex-column align-items-center py-2">
    <div className="flex align-items-center">
      <div className="block lg:hidden">
        <Menu ref={menuLeft} model={itemsLeft} popup id="popup_menu_left" className="w-20rem" />
        <Button
          text
          icon="pi pi-bars"
          severity="secondary"
          iconPos='right'
          onClick={(event) => menuLeft.current.toggle(event)}
          aria-controls="popup_menu_left"
          aria-haspopup
        />
      </div>

      <div className="title text-xl font-bold px-4 text-600">
        Osteoporosis humor
      </div>
    </div>


    <div className={`title text-sm font-${scrollY > 80 ? 'semibold' : 'bold'} px-4 text-800 hidden lg:block`}>
      <span>Whatsapp (771) 103 7615 </span>  <span className="px-3">/</span>  <span>contacto@osteoporosishumor.com</span>
    </div>
    <div>
      <div className='md:flex-row flex-column px-4 gap-2 hidden md:flex'>
        {scrollY > 80 ?
          (<a
            className="hidden lg:flex"
            style={{ textDecoration: "none" }}
            href="mailto:contacto@osteoporosis.com?subject=Información sobre publicidad&body=Hola, quiero información de la publicidad desde $50 pesos.%0D%0A%0D%0AESCRIBE TUS DATOS DE CONTACTO AQUÍ..."
          >
            <Button
              link
              label="Publicidad a partir de $50 MXN al mes"
              // severity="secondary"
              className="text-700"

            />
          </a>) : (<a
            href="mailto:contacto@osteoporosis.com?subject=Información sobre publicidad&body=Hola, quiero información de la publicidad desde $50 pesos.%0D%0A%0D%0AESCRIBE TUS DATOS DE CONTACTO AQUÍ..."
            className="header-link header-link--button hidden lg:flex">
            Publicidad a partir de $50 MXN al mes
          </a>)
        }


        {
          isLogin ? (
            <div className='flex align-items-center gap-2'>
              <Menu id="popup_menu_right" ref={menuRight} model={itemsRight} popup className="w-18rem" />
              <Button
                link
                label='Escribir'
                onClick={() => onWrite()}
              />
              <div>
                <Avatar
                  image={author.picture}
                  shape='circle'
                  onClick={(event) => menuRight.current.toggle(event)}
                  aria-controls="popup_menu_right"
                  aria-haspopup
                />
              </div>
            </div>
          )
            :
            <Button
              label='Ingresar'
              icon="pi pi-sign-in"
              iconPos='right'
              onClick={() => onLogin()}
            />
        }

      </div>
    </div>
  </div>
}