import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { useEffect, useState } from "react";

const GET_AUTHORS_BY_CODE = gql`
  query GetAuthorByCode($code: String!) {

    author(query: { code: $code }) {
      _id
      code
      name
      picture
      role
    }

  }
`;

export function useGetAuthorByCode() {
  const [refetch, info] = useLazyQuery(GET_AUTHORS_BY_CODE, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  })

  const [done, setDone] = useState(false)

  useEffect(() => {
    console.log("fetch data", info)
  }, [info.data, info.loading, info.error])

  return [async (code = "") => {
    setDone(false)
    await refetch({
      variables: {
        code
      }
    })
    setDone(true)
  }, {
    ...info,
    done,
    undone: () => setDone(false)
  }]
}