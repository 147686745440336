import React, { useEffect, useState, useRef } from "react";

import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { ImageUpload } from "./image-upload";
import { useAddArticleContext } from "../context/addArticleContext";


export const FrontPage = ({ }) => {
  const toast = useRef(null);

  const { title, setTitle } = useAddArticleContext()

  const onUpload = () => {
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  return (
    <div
      className="flex flex-column gap-3 p-3 w-full "
    >
      <span className="p-input-icon-left">
        <i className="pi pi-align-justify" />
        <InputText
          className="w-full"
          placeholder="Título del artículo"
          value={title}
          onChange={({ target }) => setTitle(target.value)}
          style={{ width: '', root: "" }}
        // pt={{
        //   root: {
        //     className: "w-full"
        //   }
        // }}
        />
      </span>

      <Toast ref={toast}></Toast>
      <ImageUpload />
    </div>
  );
}