import { useEffect, useRef, useState } from "react";

const useScrollPosition = () => {
    const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
    const ref = useRef();

    useEffect(() => {
        if (!ref.current) return

        function updatePosition() {
            setPosition({ scrollX: ref.current.scrollLeft, scrollY: ref.current.scrollTop })
        }

        ref.current.addEventListener('scroll', updatePosition)
        updatePosition()

        return () => ref.current.removeEventListener('scroll', updatePosition)
    }, [])

    return [ref, scrollPosition];
};

export default useScrollPosition;