import { gql, useMutation } from "@apollo/client"
import * as ObjectId from "bson-objectid";

const ADD_ARTICLE = gql`
  mutation AddArticle ($article: ArticleInsertInput!) {

    insertOneArticle(data: $article) {
      _id
      title
    }

  }
`;

const articleSample = () => ({
  type: "article-simple",
  title: `My article title`,
  titleSize: "small",
  image: "https://placehold.it/800",
  paragraphs: [
    "First line",
    "Second line",
    "Third <strong>line</strong>",
  ],
  primary: true,
  link: "https://fake.com",
  column: 1,
  credits: "Legacy",
  author: {
    code: "My author code",
    name: "My author name",
    role: "My author role",
    picture: "https://placehold.it/200"
  },
  createdAt: new Date().toISOString(),
  updateAt: new Date().toISOString(),
})

export function useAddArticle() {
  const [mutation, info] = useMutation(ADD_ARTICLE)

  return [async (article = articleSample()) => {
    await mutation({
      variables: {
        article: {
          _id: ObjectId(),
          ...articleSample(),
          ...article
        }
      }
    })
  }, info]
}