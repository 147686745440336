import React, { useEffect, useState, useRef } from "react";

import { useAddArticle } from "../hooks/useAddArticle";
import { useGetArticles } from "../hooks/useGetArticles";

import CustomEditor from "./editor";

import { Toast } from 'primereact/toast';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import "./add-column.css";
import { useAddArticleContext } from "../context/addArticleContext";

export const WriteArticle = ({ }) => {

  const [refetch] = useGetArticles(true)
  const [addArticle, { loading, error, data }] = useAddArticle();
  const { paragraphs, setParagraphs } = useAddArticleContext()


  const toast = useRef(null);



  useEffect(() => {
    if (data) {
      showToast({ title: "Información", message: "Artículo creado", status: "success" })
      refetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const showToast = ({ title = "", message = "", status }) => {
    toast.current.show({ severity: status, summary: title, detail: message, life: 3000 });
  };





  if (error) return <span>Error: {JSON.stringify(error)}</span>
  // if (loading) return <i className="pi pi-spin pi-spinner" style={{ position: 'absolute', top: '100px', left: '50%', fontSize: '4rem', color: 'cornflowerblue' }}></i>


  return (
    <>
      {/* <Button className="p-button-text" onClick={() => setOpenDialog(true)}>
        Escribir
      </Button> */}
      <Toast ref={toast} />



      <div >
        <CustomEditor paragraphs={paragraphs} setParagraphs={setParagraphs} />
      </div>
    </>
  )
}