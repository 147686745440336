import { gql, useMutation } from "@apollo/client"
import ObjectID from "bson-objectid";

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle ($id: ObjectId, $article: ArticleUpdateInput!) {

    updateOneArticle(query: { _id: $id }, set: $article) {
      _id
      title
    }

  }
`;

// const articleSample = () => ({
//   type: "article-simple",
//   title: `My article title`,
//   titleSize: "small",
//   image: "https://placehold.it/800",
//   paragraphs: [
//     "First line",
//     "Second line",
//     "Third <strong>line</strong>",
//   ],
//   primary: true,
//   link: "https://fake.com",
//   column: 1,
//   credits: "Legacy",
//   author: {
//     code: "My author code",
//     role: "My author role",
//     picture: "https://placehold.it/200"
//   },
//   createdAt: new Date().toISOString(),
//   updateAt: new Date().toISOString(),
// })

export function useUpdateArticle() {
  const [mutation, info] = useMutation(UPDATE_ARTICLE)

  return [async (article = null) => {
    await mutation({
      variables: {
        id: ObjectID(article._id),
        article: {
          // ...articleSample(),
          ...article,
          updateAt: new Date().toISOString()
        }
      }
    })
  }, info]
}