import { createContext, useContext, useState } from "react"

const ArticleContext = createContext({
    picture: { url: null, file: null },
    setPicture(value) { console.warn("NOT IMPLEMENT YET", { value }) },
    title: "",
    setTitle(value) { console.warn("NOT IMPLEMENT YET", { value }) },
    paragraphs: [],
    setParagraphs(value) { console.warn("NOT IMPLEMENT YET", { value }) },
    totalSize: 0,
    setTotalSize(value) { console.warn("NOT IMPLEMENT YET", { value }) },

})


export const useAddArticleContext = () => useContext(ArticleContext)

export const AddArticleProvider = ({ children }) => {
    const [picture, setPicture] = useState({ url: null, file: null })
    const [title, setTitle] = useState("")
    const [paragraphs, setParagraphs] = useState([])
    const [totalSize, setTotalSize] = useState(0)


    return (
        <ArticleContext.Provider value={{
            picture, setPicture,
            title, setTitle,
            paragraphs, setParagraphs,
            totalSize, setTotalSize
        }}>
            {children}
        </ArticleContext.Provider>
    )
}