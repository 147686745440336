import { useEffect, useRef, useState } from "react"
import { FileUpload } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"
import { useAddArticleContext } from "../context/addArticleContext"

export function ImageUpload({ }) {
    const [previewPicture, setPreviewPicture] = useState(false)
    const { picture, setPicture, totalSize, setTotalSize } = useAddArticleContext()
    // const [loading, setLoading] = useState(false)

    const maxSize = 3_000_000;
    const fileRef = useRef()


    useEffect(() => {
        if (picture.file) {
            if (fileRef.current) {
                fileRef.current.setUploadedFiles([picture.file])
            }
        } else {
            if (fileRef.current) {
                fileRef.current.clear()
            }
        }
    }, [picture])

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;
        setPreviewPicture(true)

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateRemove = ({ file }) => {
        if (!file) return
        const newTotalSize = totalSize - file.size
        setTotalSize(newTotalSize);
        setPreviewPicture(false)

    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setPreviewPicture(false)

    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = Number(totalSize) / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / {maxSize / 1_000_000} MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
                {
                    (picture.url && (!previewPicture)) && <img src={picture.url} style={{ width: '300px' }} />

                }
            </div>
        );
    };


    const handleUpload = async (event) => {
        console.log("uploadHandler", { event, fileRef })

        fileRef.current.progress = 0
        // setLoading(true)

        const file = event.files[0]

        // if (!file) {
        //     setLoading(false)
        //     return
        // }

        const fileURL = URL.createObjectURL(file)
        // const fileURL = `https://placekitten.com/${Math.floor(Math.random() * 500)}`
        // setPicture({ url: fileURL, file })

        const formData = new FormData()

        formData.append("file", file)

        const [date, time] = new Date().toISOString().slice(0, 19).replace(/:/g, "-").split("T")
        const folder = `ox/${date}/${time}`

        formData.append("folder", folder)

        try {
            const { url } = await fetch("http://3.148.104.1:8080/lab/system/uploadBucket", {
                method: "post",
                body: formData
            }).then(response => response.json())
                .then(data => data.bucket)

            // setPicture(url)

            console.log({ url })

            // setLoading(false)

            fileRef.current.clear()
            fileRef.current.setUploadedFiles(event.files)

            setPicture({ url, file })
        } catch (error) {
            console.log({ error });
            // setLoading(false);
        }
    }

    return (
        <FileUpload
            ref={fileRef}
            // multiple
            className="w-full"
            chooseLabel="Elegir Imagen"
            uploadLabel="Subir"
            cancelLabel="Cancelar"
            headerTemplate={headerTemplate}
            onProgress={event => {
                console.log("onProgress", { event })

            }}
            mode="advanced"
            accept="image/*"
            maxFileSize={maxSize}
            customUpload
            onSelect={event => {
                fileRef.current.setUploadedFiles([])
                onTemplateSelect(event)
            }}
            onClear={event => {
                if (picture.file) {
                    if (fileRef.current) {
                        fileRef.current.setUploadedFiles([picture.file])
                    }
                }

                onTemplateClear(event)
            }}
            onRemove={event => {
                onTemplateRemove(event)
                if (picture.file) {
                    setPicture({ url: null, file: null })
                }

            }}
            onUpload={onTemplateUpload}
            uploadHandler={handleUpload}
        />
    )
}


async function createFile(url) {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
        type: 'image/jpeg'
    };
    let file = new File([data], "test.jpg", metadata);
    // ... do something with the file or return it
    return file
}