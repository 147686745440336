import React, { useEffect, useRef, useState } from "react";

import { Editor } from 'primereact/editor';
import { BlockUI } from "primereact/blockui";

import Quill from "quill";
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/ImageResize', ImageResize);

const CustomEditor = ({ paragraphs, setParagraphs, fullScreen }) => {
  const editorRef = useRef();
  const [quillReady, setQuillReady] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      if (quillReady) return

      // console.warn("Esperando Quill")
      // console.log(editorRef)
      window.Quill = editorRef.current.getQuill()
      if (!window.Quill) return
      window.Quill.find = (image) => {
        // console.log({ image })
        // window.Quill.root.removeChild(params[0])
        return {
          deleteAt: (...subparams) => {
            // console.log({ subparams })
            image.remove()
          }
        }
      }
      setQuillReady(true)
      // console.log(window.Quill)
    }, 1000);
    return () => {
      clearInterval(id)
      // console.log("Borrando intervalo del Quill")
    }
  }, [quillReady])


  const handleParagraphs = (event) => {
    if (!event.htmlValue) return
    const paragraphs = event.htmlValue.split("<br>")
    // console.log({ paragraphs });
    setParagraphs(paragraphs)
  }

  const editorModules = {
    // toolbar: {
    //   container: "#toolbar",
    //   handlers: {
    //     insertStar: insertStar
    //   }
    // },
    // toolbar: [
    //   [{ header: 'H1' }, { header: 'H2' }],
    //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //   [
    //     { list: 'ordered' },
    //     { list: 'bullet' },
    //     { indent: '-1' },
    //     { indent: '+1' }
    //   ],
    //   ['link', 'image'],
    //   ['clean']
    // ],
    // clipboard: {
    //   // toggle to add extra line breaks when pasting HTML:
    //   matchVisual: true
    // },
    ImageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  }


  return <BlockUI blocked={!quillReady} template={<i className="pi pi-lock" style={{ fontSize: '3rem' }}></i>}>
    <Editor
      ref={editorRef}
      value={paragraphs.join("<br>")}
      onTextChange={handleParagraphs}
      modules={editorModules}
      style={{ height: fullScreen ? '70vh' : '40vh' }}
    />
  </BlockUI>
}

export default CustomEditor