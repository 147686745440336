import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useResize } from '../hooks/useResize';
import { useAuthorLoginContext } from '../hooks/useAuthorLogin';
import { Button } from 'primereact/button';
import { useUpdateArticle } from '../hooks/useUpdateArticle';
import { useGetArticles } from '../hooks/useGetArticles';
import { AddArticle } from './add-article';
import { useAddArticleContext } from '../context/addArticleContext';

export const OpenLinkDialog = ({ children, article, main, title, textSize, image, paragraphs, author }) => {
  const { isSmall } = useResize();

  const { author: authorContext } = useAuthorLoginContext();
  const [refetch, { loading, error, data }] = useGetArticles(true)
  const [updateArticle, updateArticleInfo] = useUpdateArticle();

  const [open, setOpen] = useState(false)
  const [openAddArticle, setOpenAddArticle] = useState(false);

  const [fullScreen, setFullScreen] = useState(false)

  const { setTitle, picture, setPicture, setParagraphs } = useAddArticleContext()

  const dialogRef = useRef()

  useEffect(() => {
    setFullScreen(false)
    if (isSmall) {
      setFullScreen(true)
    }
  }, [isSmall])


  useEffect(() => {
    if (updateArticleInfo.data) {
      refetch()
      setOpen(false)
    }
  }, [updateArticleInfo.data])


  const handleUpdateArticle = async () => {
    await updateArticle({
      _id: article._id,
      primary: false
    })

  }

  const handleEditArticle = () => {
    setTitle(title)
    setParagraphs(paragraphs)
    setPicture({
      ...picture,
      url: image
    })
    setOpenAddArticle(true)
  }

  const handleMaximize = (event) => setFullScreen(event.maximized)

  if (!title || title === "") return "SIN TÍTULO"

  return (
    <>
      <Dialog
        ref={dialogRef}
        header={title}
        maximizable={!isSmall}
        maximized={fullScreen}
        onMaximize={handleMaximize}
        visible={open}
        style={{ width: '70vw' }}
        closeOnEscape={true}
        onHide={() => setOpen(false)}
        pt={{
          content: {
            className: "px-2 md:px-6"
          }
        }}
      >
        {
          articleMapper(title, image, paragraphs, author, authorContext, handleUpdateArticle, handleEditArticle)
        }
      </Dialog>

      {
        children ?
          (<div onClick={() => setOpen(!open)}>
            {children}
          </div>)
          : (<>
            <h1 className={`hover:underline cursor-pointer ${textSize}`} onClick={() => setOpen(!open)}>
              {title}
            </h1>
            {
              main && image && (
                <img style={{ objectFit: "cover", cursor: 'pointer' }} className="main-image w-full h-30rem" src={image} onClick={() => setOpen(!open)} />
              )
            }
          </>)
      }

      <AddArticle
        articleId={article._id}
        update={true}
        visible={openAddArticle}
        onComplete={() => {
          setOpen(false)
          setOpenAddArticle(false)
          refetch()
        }}
        createdAt={article?.createdAt}
      />
    </>
  );
}


const articleMapper = (title, image, paragraphs, author, authorContext, handleUpdateArticle, handleEditArticle) => (
  <div className='px-2 md:px-6 lg:px-8'>
    <div className="text-gray-800 font-italic">
      {/* <h1>
                {title || "SIN TÍTULO"}
            </h1> */}
      {
        image && (
          <img style={{ objectFit: "cover" }} className="w-full h-28rem" src={image} />
        )
      }
      <div className="flex py-4 gap-2">
        <div>
          {
            author && (
              <div className="flex align-items-center lg:justify-content-start justify-content-center">
                <img className="border-circle w-3rem h-3rem" src={author.picture} />
                <div>
                  <div className="px-3 flex flex-column align-items-baseline">
                    <span>{author.name}</span>
                    <span className="text-xs">{author.role}</span>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div>
          {
            author?.name === authorContext?.name && (
              <div>
                <Button
                  text
                  severity='info'
                  label="Editar"
                  icon="pi pi-file-edit"
                  onClick={handleEditArticle}
                />
                <Button
                  text
                  severity='info'
                  label="Archivar"
                  icon="pi pi-inbox"
                  onClick={handleUpdateArticle}
                />
              </div>
            )
          }
        </div>

      </div>
      {
        (paragraphs) && (
          <div dangerouslySetInnerHTML={{
            __html: paragraphs.map(line =>
              line.replace(/\<img/g, `<img style="height: 420px; object-fit: contain;" class="w-full py-2"`).replace(/\<iframe/g, `<iframe style="height: 420px; object-fit: contain;" class="w-full py-2"`)).join("\n")
          }} />
        )
      }
      <div className="py-5">
        <div className="w-full border-none border-bottom-1 border-solid border-200" />
      </div>
    </div>

  </div>
)
